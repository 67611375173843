import React, { Component } from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Col, Row, Form, FormGroup, Label, Input, Button, FormFeedback } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import { Link, navigate } from "gatsby";
import ContentContainer from "../components/ContentContainer";
import AuthedRoute from "../components/AuthedRoute";

class Signup extends Component {

	state = {
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		confirmPassword: "",
		validation: {
			email: false,
			password: false,
		},
		touched: false,
		error: "",
	};

	validatePassword(e) {
		const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)\S{8,}$/;
		this.setState({ validation: { ...this.state.validation, password: passwordRegex.test(e.target.value) } });
	}

	validateEmail(e) {
		// eslint-disable-next-line
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		this.setState({ validation: { ...this.state.validation, email: emailRegex.test(e.target.value) } });
	}

	handleChange(e) {
		const { target } = e;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const { name } = target;
		this.setState({
			[name]: value,
		});
	}

	async handleSubmit(e) {
		e.preventDefault();
		await this.setState({
			touched: true,
		});

		if (Object.values(this.state.validation).reduce((a, b) => a && b)) {
			try {
				await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/users/signup`, {
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					email: this.state.email,
					password: this.state.password,
				});
				navigate("/signup-success");
			} catch (err) {
				await this.setState({
					error: err.request.response,
				})
			}
		}
	}

	render() {
		const { touched, firstName, lastName, email, password, confirmPassword } = this.state;
		return (
			<AuthedRoute inverted>
				<Layout>
					<SEO title="Registrieren"/>
					<ContentContainer half>
						<Row>
							<Col sm="12">
								<div className="text-center mw-75 d-block mx-auto">
									<h1 className="font-weight-bold mt-5">Registrieren</h1>
									<p className="mt-3 color-subtext">Du bist nur noch 5 Felder von deiner erfolgreichen Registrierung mit jeder Menge Bonus Features entfernt! </p>
								</div>
								<Form onSubmit={ e => this.handleSubmit(e) } className="mw-50 d-block mx-auto">
									<Row form>
										<Col md={ 6 }>
											<FormGroup>
												<Label for="firstName">First name</Label>
												<Input type="text" name="firstName" id="firstName" placeholder="Max"
													className="round"
													value={ firstName }
													invalid={ touched && !firstName }
													valid={ touched && !!firstName }
													onChange={ e => this.handleChange(e) }
												/>
												<FormFeedback>
													Ein Vorname wäre schon toll!
												</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={ 6 }>
											<FormGroup>
												<Label for="lastName">Last name</Label>
												<Input type="text" name="lastName" id="lastName" placeholder="Mustermann"
													className="round"
													value={ lastName }
													invalid={ touched && !lastName }
													valid={ touched && !!lastName }
													onChange={ e => this.handleChange(e) }
												/>
												<FormFeedback>
													Ein Nachname wäre schon toll!
												</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<FormGroup>
										<Label for="email">Email</Label>
										<Input type="email" name="email" id="email" placeholder="muster@mail.de"
											className="round"
											value={ email }
											valid={ touched && this.state.validation.email }
											invalid={ touched && !this.state.validation.email }
											onChange={ e => {
												this.validateEmail(e);
												this.handleChange(e)
											} }
										/>
										<FormFeedback valid>
											Sieht gut aus!
										</FormFeedback>
										<FormFeedback>
											Irgendwas ist komisch..
										</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="password">Passwort</Label>
										<Input type="password" name="password" id="password" placeholder="********"
											className="round"
											value={ password }
											valid={ touched && this.state.validation.password }
											invalid={ touched && !this.state.validation.password }
											onChange={ e => {
												this.validatePassword(e);
												this.handleChange(e)
											} }
										/>
										<FormFeedback valid>
											<span role="img" aria-label="thumbsup emoji">👍</span>
										</FormFeedback>
										<FormFeedback>
											Passwort unsicher! (8 Zeichen, min. 1 Buchstabe, min. 1 Zahl)
										</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="confirmPassword">Confirm password</Label>
										<Input type="password" name="confirmPassword" id="confirmPassword"
											placeholder="********"
											className="round"
											value={ confirmPassword }
											valid={ touched && confirmPassword && confirmPassword === password }
											invalid={ touched && (!confirmPassword || confirmPassword !== password) }
											onChange={ e => this.handleChange(e) }
										/>
										<FormFeedback>
											Die Passwörter müssen übereinstimmen
										</FormFeedback>
									</FormGroup>
									<p className="feedback-error">{ this.state.error }</p>
									<Button color="primary" block className="round mt-4 py-2">Anmelden</Button>
									<div className="d-flex justify-content-between color-subtext mt-1 px-4">
										<Link to="/login" className="font-weight-bold">Hast du schon einen Account? Melde dich an!</Link>
										<Link to="/privacy-policy">Datenschutzbestimmungen</Link>
									</div>
								</Form>
							</Col>
						</Row>
					</ContentContainer>
				</Layout>
			</AuthedRoute>
		);
	}
}

export default connect(state => ({}), null)(Signup);
